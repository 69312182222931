import React, { SyntheticEvent } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import environment from '../../../../../environment';
import RemoveIcon from '../../../../../assets/icons/disable.svg';
import { ISkuGroup } from '../../../../../interfaces/ISkuGroup';
import GenericModal from '../../../../../components/GenericModal';

const RemoveSkuGroupModal: React.FC<{
  id: string;
  skuGroup: string;
  setRowData: (data: ISkuGroup[]) => void;
  rowData: ISkuGroup[];
}> = ({ id, skuGroup, setRowData, rowData }) => {
  const handleSubmit =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);

      axios
        .delete(`${environment.apiPath}skuGroup/${id}`, {
          ...environment.params
        })
        .then(
          () => {
            setRowData(rowData.filter((item: ISkuGroup) => item.id !== id));
            toast.success(`SKU group ${skuGroup} successfully removed`);
          },
          (err) => toast.error(err.message)
        )
        .finally(() => {
          setLoading(false);
          setOpen(false);
        });
    };

  return (
    <GenericModal
      iconBtn={RemoveIcon}
      confirmationMessage={`Please confirm the removal of the following SKU Group: ${skuGroup}`}
      handleSubmit={handleSubmit}
      style={{ filter: 'brightness(0) saturate(100%)' }}
    />
  );
};

export default RemoveSkuGroupModal;
