import { Container } from '@mui/material';
import styled from 'styled-components';

export default styled(Container)`
  .main_report {
    height: 600px;
  }

  .report__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .report__actions--divider {
    display: flex;
    gap: 10px;
  }

  .report__grid {
    width: 100%;
    margin: 30px 0;
    font-size: 14px;
    font-family: Vodafone Lt;
  }

  .cell__actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn--light-red {
    color: ${(props) => props.theme.colors.lightRed};
  }

  .btn--light-blue {
    color: ${(props) => props.theme.colors.lightBlue};
  }
`;
