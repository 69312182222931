import { Container } from '@mui/material';
import styled from 'styled-components';

export default styled(Container)`
  .report__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .report__grid {
    height: 600px;
    width: 100%;
    margin: 30px 0;
    font-size: 14px;
    font-family: Vodafone Lt;

    .report-cell-red {
      background-color: ${(props) => props.theme.colors.lightRed};
      color: white;
    }

    .report-cell-amber {
      background-color: ${(props) => props.theme.colors.yellow};
      color: white;
    }

    .report-cell-green {
      background-color: ${(props) => props.theme.colors.lightGreen};
      color: white;
    }
  }

  .last-updated {
    margin-top: 20px;
    align-self: center;
  }
  .last-update-green {
    color: ${(props) => props.theme.colors.lightGreen};
  }

  .last-update-amber {
    color: ${(props) => props.theme.colors.yellow};
  }

  .last-update-red {
    color: ${(props) => props.theme.colors.lightRed};
  }

  // .ag-theme-material .ag-header-cell-label .ag-header-cell-text {
  //   white-space: pre;
  // }

  .ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
    overflow: clip !important;
  }

  .ag-cell {
    white-space: normal !important;
    overflow: clip !important;
  }
`;
