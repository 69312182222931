import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { AgGridReact } from 'ag-grid-react';
import { GridApi, GridReadyEvent } from 'ag-grid-community';

import StyledSubProducts from './styles';
import environment from '../../../../../environment';
import ManageProductsModal from '../ManageProductsModal';
import {
  ISkuGroup,
  ISkuGroupProduct
} from '../../../../../interfaces/ISkuGroup';

const SubProducts: React.FC<{
  setRowData: (data: ISkuGroup[]) => void;
  rowData: ISkuGroup[];
  data: ISkuGroup;
}> = ({ setRowData, rowData, data }) => {
  const { id, skuGroup } = data;
  const [rowProductsData, setProductsRowData] = useState<ISkuGroupProduct[]>(
    []
  );
  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridLoading, setGridLoading] = useState(true);

  const getRowData = (gridApiParam: GridApi) => {
    axios
      .get(`${environment.apiPath}skuGroup/${id}`, {
        ...environment.params
      })
      .then(
        (res) => {
          setProductsRowData(res.data);
          setGridLoading(false);
          if (res.data.length === 0) {
            gridApiParam?.showNoRowsOverlay();
          }
        },
        (err) => {
          setProductsRowData([]);
          setGridLoading(false);
          toast.error(err.message);
          gridApiParam?.showNoRowsOverlay();
        }
      );
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api?.showLoadingOverlay();
    setGridApi(params.api);
    getRowData(params.api);
  };

  const columnDefs = [
    { headerName: 'SKU', field: 'productCode', maxWidth: 120 },
    { headerName: 'Description', field: 'description' }
  ];

  return (
    <StyledSubProducts>
      <ManageProductsModal
        id={id}
        skuGroup={skuGroup}
        rowProductsData={rowProductsData}
        setProductsRowData={setProductsRowData}
        setRowData={setRowData}
        rowData={rowData}
        gridApi={gridApi}
        gridLoading={gridLoading}
      />
      <div className='detail__grid ag-theme-balham'>
        <AgGridReact
          rowHeight={30}
          defaultColDef={{
            resizable: true,
            flex: 1,
            minWidth: 100,
            filter: 'agTextColumnFilter'
          }}
          enableCellTextSelection
          suppressRowClickSelection
          pagination
          rowData={rowProductsData}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          rowGroupPanelShow='always'
          suppressDragLeaveHidesColumns
        />
      </div>
    </StyledSubProducts>
  );
};

export default SubProducts;
