import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { AgGridReact } from 'ag-grid-react';
import { GridApi, GridReadyEvent } from 'ag-grid-community';

import StyledScheduledReportFiles from './styles';
import environment from '../../../../../environment';
import DownloadButton from './DownloadButton';
import { dateHourFormatterReport } from '../../../../../utils';
import {
  IScheduledReport,
  IScheduledReportFile
} from '../../../../../interfaces/IScheduledReport';

const ScheduledReportFiles: React.FC<{
  data: IScheduledReport;
}> = ({ data }) => {
  const [generatedFiles, setGeneratedFiles] = useState<IScheduledReportFile[]>(
    []
  );

  const getRowData = (gridApiParam: GridApi) => {
    axios
      .get(
        `${environment.apiPath}getScheduledReportFiles/${data.scheduledReportId}`,
        {
          ...environment.params
        }
      )
      .then(
        (res) => {
          setGeneratedFiles(res.data);
          if (res.data.length === 0) {
            gridApiParam?.showNoRowsOverlay();
          }
        },
        (err) => {
          setGeneratedFiles([]);
          toast.error(err.message);
          gridApiParam?.showNoRowsOverlay();
        }
      );
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api?.showLoadingOverlay();
    getRowData(params.api);
  };

  const columnDefs = [
    {
      headerName: 'Filename',
      field: 'filename',
      sortable: true,
      resizable: true,
      flex: 1
    },
    {
      headerName: 'Created Date',
      field: 'createdDate',
      sortable: true,
      resizable: true,
      flex: 1,
      valueGetter: (params: any) =>
        params.data && params.data.createdDate
          ? dateHourFormatterReport(params.data.createdDate)
          : ''
    },
    {
      headerName: 'Action',
      field: 'action',
      cellRenderer: 'downloadButton',
      sortable: false,
      resizable: true,
      flex: 1
    }
  ];

  return (
    <StyledScheduledReportFiles>
      <div className='detail__grid ag-theme-balham'>
        <AgGridReact
          rowHeight={30}
          defaultColDef={{
            sortable: true,
            resizable: true,
            flex: 1
          }}
          enableCellTextSelection
          suppressRowClickSelection
          pagination
          paginationPageSize={5}
          rowData={generatedFiles}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          suppressDragLeaveHidesColumns
          components={{
            downloadButton: DownloadButton
          }}
        />
      </div>
    </StyledScheduledReportFiles>
  );
};

export default ScheduledReportFiles;
