import React, { ChangeEvent, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import HeroBanner from '../../../../../components/HeroBanner';
import ScheduleReportModal from '../ScheduleReportModal';
import HeroProducts from '../../../../../assets/heros/hero-products.png';
import CustomButton from '../../../../../components/CustomButton';
import ArrowRight from '../../../../../assets/icons/arrow-right.svg';
import StyledScheduledReports from './styles';
import environment from '../../../../../environment';
import { dateHourFormatter } from '../../../../../utils';
import EnableButton from './EnableButton';
import ControlledInput from '../../../../../components/ControlledInput';
import ScheduledReportFiles from '../ScheduledReportFiles';
import { IScheduledReport } from '../../../../../interfaces/IScheduledReport';

const ScheduledReports: React.FC = () => {
  const navigate = useNavigate();

  const [rowData, setRowData] = useState<IScheduledReport[]>([]);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [quickFilter, setQuickFilter] = useState<string>('');

  const getRowData = (gridApiParam: GridApi) => {
    axios
      .get(`${environment.apiPath}getScheduledReports`, {
        ...environment.params
      })
      .then(
        (res) => {
          const sortedScheduledReports: IScheduledReport[] = res.data;
          sortedScheduledReports.sort((a, b) =>
            a.reportName.localeCompare(b.reportName)
          );
          setRowData(sortedScheduledReports);
          if (res.data.length === 0) {
            gridApiParam?.showNoRowsOverlay();
          }
        },
        (err) => {
          setRowData([]);
          toast.error(err.message);
          gridApiParam?.showNoRowsOverlay();
        }
      );
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api?.showLoadingOverlay();
    setGridApi(params.api);
    getRowData(params.api);
  };

  const columnDefs = [
    {
      headerName: 'Report Name',
      field: 'reportName',
      minWidth: 300
    },
    {
      headerName: 'Report Query',
      field: 'queryName',
      minWidth: 300
    },
    {
      headerName: 'Frequency',
      field: 'frequency',
      valueGetter: (params: any) =>
        params.data && params.data.frequency
          ? params.data.frequency.charAt(0).toUpperCase() +
            params.data.frequency.slice(1)
          : ''
    },
    {
      headerName: 'Start Date',
      field: 'startDate',
      valueGetter: (params: any) =>
        params.data && params.data.startDate
          ? dateHourFormatter(params.data.startDate)
          : ''
    },
    {
      headerName: 'End Date',
      field: 'endDate',
      valueGetter: (params: any) =>
        params.data && params.data.endDate
          ? dateHourFormatter(params.data.endDate)
          : ''
    },
    {
      headerName: 'Status',
      field: 'enabled',
      valueGetter: (params: any) =>
        params.data ? (params.data.enabled ? 'Enabled' : 'Disabled') : '',
      enableRowGroup: true
    },
    {
      headerName: 'Action',
      field: 'action',
      cellRendererSelector: (params: any) =>
        params.node && params.node.group
          ? { component: '' }
          : { component: 'enableButton' }
    }
  ];

  return (
    <>
      <HeroBanner title='Reporting' background={HeroProducts} />
      <StyledScheduledReports fixed>
        <div className='reporting__actions'>
          <ControlledInput
            id='quick-filter'
            placeholder='Filter any column'
            value={quickFilter}
            handleChange={(event: ChangeEvent<HTMLInputElement>) => {
              setQuickFilter(event.target.value);
              gridApi?.setQuickFilter(event.target.value);
            }}
            label='Grid filter'
            type='text'
            classes='label--w-30'
          />
          <div>
            <ScheduleReportModal rowData={rowData} setRowData={setRowData} />
            <CustomButton
              type='button'
              classes='btn--w-200-px btn--grey btn--ml-10'
              title='Reporting'
              endIcon={
                <img src={ArrowRight} alt='Navigate to reporting page' />
              }
              handleClick={() => navigate('/reporting/order')}
            />
          </div>
        </div>
        <div className='reporting__grid ag-theme-balham'>
          <AgGridReact
            masterDetail
            rowHeight={30}
            defaultColDef={{
              resizable: true,
              flex: 1,
              minWidth: 100,
              filter: 'agTextColumnFilter'
            }}
            detailRowHeight={250}
            components={{
              detailCellRenderer: ScheduledReportFiles,
              enableButton: EnableButton
            }}
            enableCellTextSelection
            suppressRowClickSelection
            suppressDragLeaveHidesColumns
            rowGroupPanelShow='always'
            pagination
            rowData={rowData}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            detailCellRenderer='detailCellRenderer'
            rowClassRules={{
              'disabled-row': (params: any) =>
                params.node.group ? false : !params.data.enabled
            }}
          />
        </div>
      </StyledScheduledReports>
    </>
  );
};

export default ScheduledReports;
