import React, { useState, SyntheticEvent, ChangeEvent } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { isNull, isUndefined } from 'lodash';

import ControlledInput from '../../../../../components/ControlledInput';
import ControlledCheckbox from '../../../../../components/ControlledCheckbox';
import environment from '../../../../../environment';
import CustomDatePicker from '../../../../../components/CustomDatePicker';
import {
  IInventoryChannel,
  IInventoryItem,
  ISubInventory,
  IInventoryData
} from '../../../../../interfaces/IInventoryItem';
import GenericModal from '../../../../../components/GenericModal';

interface ISubInventoryProps {
  productCode: string;
  productDescription: string;
  productIsVirtual: boolean;
  channel: string;
  subinventory: ISubInventory;
  inventoryData: IInventoryData;
  setInventoryData: CallableFunction;
  allowStockChange?: boolean;
}

const SubinventoryModal: React.FC<ISubInventoryProps> = ({
  productCode,
  productDescription,
  productIsVirtual,
  channel,
  subinventory,
  inventoryData,
  setInventoryData,
  allowStockChange
}) => {
  const [stockOnHand, setStockOnHand] = useState(subinventory.stockOnHand);
  const [stockOnHandError, setStockOnHandError] = useState('');
  const [canSell, setCanSell] = useState(subinventory.canSell);
  const [forwardOrder, setForwardOrder] = useState(subinventory.forwardOrder);
  const [backOrder, setBackOrder] = useState(subinventory.backOrder);
  const [preorderLimit, setPreorderLimit] = useState(
    subinventory.preorderLimit
  );
  const [preorderLimitError, setPreorderLimitError] = useState('');
  const [releaseDate, setReleaseDate] = useState(
    subinventory.releaseDate ? new Date(subinventory.releaseDate) : null
  );
  const [helperText, setHelperText] = useState('');

  const [minReleaseDate, setMinReleaseDate] = useState(
    subinventory.releaseDate
      ? new Date(subinventory.releaseDate)
      : moment().add(2, 'days').toDate()
  );
  const [dateError, setDateError] = useState(false);

  const releasePickerDisabled =
    subinventory.releaseDate !== null &&
    moment(subinventory.releaseDate).isSameOrBefore(
      moment().add(2, 'days').toDate()
    );

  const handleStockOnHandChange = (event: ChangeEvent<HTMLInputElement>) => {
    const stock: number = parseInt(event.target.value, 10);
    setStockOnHand(stock);

    if (
      isNull(event.target.value) ||
      isUndefined(event.target.value) ||
      event.target.value === ''
    ) {
      setStockOnHandError('Required field');
    } else if (stock < subinventory.allocated) {
      setStockOnHandError(`
        Invalid field: Stock on hand cannot be lower than the allocated reservations number: ${subinventory.allocated}`);
    } else {
      setStockOnHandError('');
    }
  };

  const handlePreorderLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    const limit: number = parseInt(event.target.value, 10);
    setPreorderLimit(limit);

    if (
      isNull(event.target.value) ||
      isUndefined(event.target.value) ||
      event.target.value === ''
    ) {
      setPreorderLimitError('Required field');
    } else {
      setPreorderLimitError('');
    }
  };

  const getWarnings = () => {
    const warnings: string[] = [
      'All changes made through this form will be overwritten by the next RMS catalogue update'
    ];

    if (productIsVirtual) {
      warnings.push(
        'Disabled attributes cannot be changed because the item is virtual'
      );
    }

    return warnings;
  };

  const clearForm = () => {
    setStockOnHand(subinventory.stockOnHand);
    setStockOnHandError('');
    setCanSell(subinventory.canSell);
    setForwardOrder(subinventory.forwardOrder);
    setBackOrder(subinventory.backOrder);
    setReleaseDate(
      subinventory.releaseDate ? new Date(subinventory.releaseDate) : null
    );
    setMinReleaseDate(
      subinventory.releaseDate
        ? new Date(subinventory.releaseDate)
        : moment().add(2, 'days').toDate()
    );
    setPreorderLimit(subinventory.preorderLimit);
    setPreorderLimitError('');
  };

  const handleSuccess = () => {
    toast.success(`
      Subinventory ${subinventory.subinventory} successfully updated for product ${productCode}`);
    setInventoryData({
      ...inventoryData,
      items: inventoryData.items.map((item: IInventoryItem) =>
        item.productCode === productCode
          ? {
              ...item,
              channels: item.channels.map((ch: IInventoryChannel) =>
                ch.channelName === channel
                  ? {
                      ...ch,
                      subinventories: ch.subinventories.map(
                        (sb: ISubInventory) =>
                          sb.subinventory === subinventory.subinventory
                            ? {
                                ...sb,
                                stockOnHand,
                                canSell,
                                forwardOrder,
                                backOrder,
                                releaseDate,
                                preorderLimit,
                                canReleasePreorders: releaseDate
                                  ? new Date(releaseDate) > new Date()
                                  : false
                              }
                            : sb
                      )
                    }
                  : ch
              )
            }
          : item
      )
    });
  };

  const handleSubmit =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);

      axios
        .put(
          `${environment.apiPath}product/${productCode}/${subinventory.subinventory}`,
          {
            stockOnHand,
            canSell,
            forwardOrder,
            backOrder,
            releaseDate: releaseDate ? moment(releaseDate).format() : '',
            preorderLimit
          },
          { ...environment.params }
        )
        .then(
          () => handleSuccess(),
          (err) => {
            if (err.response) {
              toast.error(err.response.data.error);
            } else {
              toast.error(err.message);
            }
            clearForm();
          }
        )
        .finally(() => {
          setLoading(false);
          setOpen(false);
        });
    };

  return (
    <GenericModal
      btnTitle='Edit subinventory'
      disabled={!allowStockChange}
      clearForm={clearForm}
      formTitle={productCode}
      formSubtitle={<h3>{productDescription}</h3>}
      formDescription={`${channel} - ${subinventory.subinventory}`}
      formError={!!stockOnHandError || !!preorderLimitError || dateError}
      warnings={getWarnings()}
      classes='btn--small btn--grey'
      handleSubmit={handleSubmit}
    >
      <ControlledInput
        id='soh'
        label='Stock on hand'
        type='number'
        value={stockOnHand}
        placeholder='Type stock on hand here'
        handleChange={handleStockOnHandChange}
        disabled={productIsVirtual}
        error={stockOnHandError}
        required
      />
      <ControlledCheckbox
        checked={canSell}
        handleChange={(event: ChangeEvent<HTMLInputElement>) =>
          setCanSell(event.target.checked)
        }
        label='Can sell'
      />
      <ControlledCheckbox
        checked={forwardOrder}
        handleChange={(event: ChangeEvent<HTMLInputElement>) =>
          setForwardOrder(event.target.checked)
        }
        label='Forward order'
        disabled={productIsVirtual}
      />
      <ControlledCheckbox
        checked={backOrder}
        handleChange={(event: ChangeEvent<HTMLInputElement>) =>
          setBackOrder(event.target.checked)
        }
        label='Backorder'
        disabled={productIsVirtual}
      />
      {releasePickerDisabled && (
        <p className='dialog-content__form__release-warning'>
          <span>&#9888; </span>
          Release date cannot be changed because it has to be set up 48H in
          advance
        </p>
      )}
      <CustomDatePicker
        id='release-date'
        value={releaseDate}
        handleChange={(event: any) => {
          setMinReleaseDate(moment().add(2, 'days').toDate());
          setReleaseDate(event);
        }}
        label='Release date'
        disabled={productIsVirtual || releasePickerDisabled}
        minDate={minReleaseDate}
        helperText={helperText}
        onError={(error: any) => {
          setDateError(error);
          setHelperText(
            error ? 'Release date has to be set up 48H in advance' : ''
          );
        }}
      />
      <ControlledInput
        id='preorderLimit'
        label='Preorder limit'
        type='number'
        value={preorderLimit}
        placeholder='Type preorder limit here'
        handleChange={handlePreorderLimitChange}
        error={preorderLimitError}
        required
      />
    </GenericModal>
  );
};

SubinventoryModal.defaultProps = {
  allowStockChange: false
};

export default SubinventoryModal;
