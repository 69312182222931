import { Container } from '@mui/material';
import styled from 'styled-components';

export default styled(Container)`
  background-color: ${(props) => props.theme.colors.lightGrey};
  padding: 5px 20px;
  margin-top: 10px;

  .changes-header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .changes-report__actions {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      margin: 20px;
    }
  }
  .report__grid {
    height: 230px;
    font-size: 14px;
    font-family: Vodafone Lt;
  }
  .change_report {
    height: 230px;
  }
`;
