import React, { useEffect, useState } from 'react';
import Rating from '@mui/material/Rating';
import { ICustomCellRendererParams } from '../../Forecasting';

interface IStarRatingProps {
  params: ICustomCellRendererParams;
  label: string;
  limit?: number;
  disabled?: boolean;
}

const StarRating: React.FC<IStarRatingProps> = ({
  params,
  label,
  limit,
  disabled
}) => {
  const [rating, setRating] = useState<number>(params.value as number);

  useEffect(() => {
    setRating(params.value as number);
  }, [params]);

  return (
    <Rating
      className={params.classes}
      value={rating}
      max={limit}
      onChange={(event, newValue) => {
        if (newValue !== null) {
          (params.setValue ?? (() => {}))(String(newValue));
          setRating(newValue);
        }
      }}
      readOnly={params.readOnly ?? false}
      disabled={disabled}
      aria-label={label}
    />
  );
};

StarRating.defaultProps = {
  limit: 3,
  disabled: false
};

export default StarRating;
