import React, { useState, SyntheticEvent } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { IGenericEntry } from '../../../../interfaces/IGenericSection';
import ControlledSelect from '../../../../components/ControlledSelect';
import environment from '../../../../environment';
import { IDeliveryGroup } from '../../../../interfaces/IOrder';
import GenericModal from '../../../../components/GenericModal';

interface ITradeInGoodsInProps {
  orderID: string;
  deliveryGroup: IDeliveryGroup;
  setButtonVisible: CallableFunction;
}

const TradeInGoodsInModal: React.FC<ITradeInGoodsInProps> = ({
  orderID,
  deliveryGroup,
  setButtonVisible
}) => {
  const [eventCodes] = useState<IGenericEntry[]>([
    { code: '20', value: '20 - Trade in Received' },
    { code: '21', value: '21 - Blacklisted' },
    { code: '22', value: '22 - Counteroffer Accepted' },
    { code: '23', value: '23 - Counteroffer Rejected' },
    { code: '24', value: '24 - Trade in Completed' },
    { code: '25', value: '25 - Trade in Rejected' },
    { code: '26', value: '26 - Trade in Expired' }
  ]);
  const [eventCode, setEventCode] = useState<IGenericEntry>(eventCodes[0]);

  const clearForm = () => {
    setEventCode(eventCodes[0]);
  };

  const handleSubmit =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);

      axios
        .post(
          `${environment.apiPath}sendTradeInGoodsIn`,
          {
            orderID,
            deliveryGroupNumber: deliveryGroup.number,
            eventCode: eventCode.code
          },
          { ...environment.params }
        )
        .then(
          () => {
            setOpen(false);
            setLoading(false);
            setButtonVisible(false);
          },
          (err) => {
            toast.error(err.message);
            clearForm();
          }
        )
        .finally(() => {
          setOpen(false);
          setLoading(false);
        });
    };

  return (
    <GenericModal
      btnTitle='Update Trade-in'
      classes='btn--blue btn--mr-10'
      clearForm={clearForm}
      formTitle='Update Trade-in Status'
      formSubtitle={
        <h3>
          {`${deliveryGroup.number} - ${deliveryGroup.details.dispatchMethodName}, 
          ${deliveryGroup.details.serviceName}`}
        </h3>
      }
      formError={!eventCode}
      handleSubmit={handleSubmit}
    >
      <ControlledSelect
        id='event-code'
        value={eventCode}
        handleChange={(event: any) => setEventCode(event.target.value)}
        label='Event'
        options={eventCodes}
        classes='label--w-100'
        required
      />
    </GenericModal>
  );
};

export default TradeInGoodsInModal;
