import React, { ChangeEvent, useRef, useState, useEffect } from 'react';
import { Chip, Button, Alert } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

import StyledProductSearch from './styles';
import ViewMoreIcon from '../../assets/icons/view-more.svg';
import ControlledInput from '../ControlledInput';
import CustomButton from '../CustomButton';
import environment from '../../environment';
import { IInventoryItem } from '../../interfaces/IInventoryItem';

interface IProductCodeSearchProps {
  productCodes: string[];
  setProductCodes: CallableFunction;
  errorMessage: string;
  setErrorMessage: CallableFunction;
  maxLen?: number;
  classes?: string;
  style?: any;
}

const ProductCodeSearch: React.FC<IProductCodeSearchProps> = ({
  productCodes,
  setProductCodes,
  maxLen,
  classes,
  style,
  errorMessage,
  setErrorMessage
}) => {
  const [productCode, setProductCode] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [viewMoreBtnVisible, setViewMoreBtnVisible] = useState(false);
  const overflowingText = useRef<HTMLDivElement | null>(null);

  const checkProductCodesOverflow = (container: HTMLDivElement | null) => {
    if (container) {
      if (expanded) {
        setViewMoreBtnVisible(container.offsetHeight > 100);
      } else {
        setViewMoreBtnVisible(container.offsetHeight < container.scrollHeight);
      }
    }
  };

  const addProductCode = () => {
    const newProducts = productCode.split(',');
    axios
      .get(`${environment.apiPath}product?productCode=${productCode}`, {
        ...environment.params
      })
      .then(
        (res) => {
          const codes = res.data.items.map(
            (product: IInventoryItem) => product.productCode
          );
          setProductCodes([
            ...productCodes,
            ...codes.filter(
              (product: string) => !productCodes.includes(product)
            )
          ]);

          const errCodes = newProducts.filter(
            (product: string) => !codes.includes(product.replace(/\s/g, ''))
          );
          if (errCodes.length > 0) {
            const { sizeLimit } = res.data;
            const allErrorCodes = errCodes.join(', ');

            setErrorMessage(
              newProducts.length <= sizeLimit
                ? `Product(s) ${allErrorCodes} not found.`
                : `Products ${allErrorCodes} were not added, because the limit of ${sizeLimit} was exceeded.`
            );
          } else {
            setErrorMessage('');
          }

          setProductCode('');
        },
        (err) => toast.error(err.message)
      );
  };

  const removeProductCode = (product: string) => {
    setProductCodes(productCodes.filter((item) => item !== product));
  };

  useEffect(() => {
    checkProductCodesOverflow(overflowingText.current);
  }, [productCodes]);

  return (
    <StyledProductSearch expanded={expanded} style={style}>
      <div className='product-code-search'>
        <div className='product-code-search__input'>
          <ControlledInput
            id='product-code'
            placeholder='Type SKU here'
            value={productCode}
            handleChange={(event: ChangeEvent<HTMLInputElement>) =>
              setProductCode(event.target.value.trim())
            }
            maxLen={maxLen}
            label='SKU'
            type='text'
            classes='label--w-100'
          />
          <CustomButton
            type='button'
            classes='btn--w-10 btn--blue'
            title='Add'
            disabled={!productCode.trim()}
            handleClick={() => addProductCode()}
          />
        </div>
        {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}
      </div>
      <div
        ref={overflowingText}
        className={`product-codes-container ${classes}`}
      >
        {productCodes.map((product: string) => (
          <Chip
            key={product}
            label={product}
            onDelete={() => removeProductCode(product)}
          />
        ))}
      </div>
      {viewMoreBtnVisible && (
        <Button
          onClick={() => setExpanded(!expanded)}
          className={`view-more-button ${classes}`}
        >
          <span style={{ color: 'white' }}>
            {expanded ? 'View less' : 'View more'}
          </span>
          <img src={ViewMoreIcon} alt='ViewMore' />
        </Button>
      )}
    </StyledProductSearch>
  );
};

ProductCodeSearch.defaultProps = {
  maxLen: 100,
  classes: '',
  style: null
};

export default ProductCodeSearch;
