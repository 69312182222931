import React, { SyntheticEvent } from 'react';
import { Container, MenuItem, Select } from '@mui/material';

import StyledSearchMenu from './styles';
import CustomButton from '../../../../components/CustomButton';
import Label from '../../../../components/Label';
import StyledFormControl from '../../../../components/ControlledSelect/styles';
import AutoCompleteSearch from '../../../../components/AutoCompleteSearch/AutoCompleteSearch';
import ProductCodeSearch from '../ProductCodeSearch/ProductCodeSearch';
import ControlledCheckbox from '../../../../components/ControlledCheckbox';

interface ISearchMenuProps {
  productCodes: string[];
  setProductCodes: CallableFunction;
  storeOptions: string[];
  stores: string[];
  setStores: CallableFunction;
  daysCover: string;
  setDaysCover: CallableFunction;
  setstoreTier: CallableFunction;
  repRequired: boolean;
  setRepRequired: CallableFunction;
  storeTier: string;
  forecastDates: Date[];
  selectedDate: Date | undefined;
  setSelectedDate: CallableFunction;
  handleSubmit: (event: SyntheticEvent) => void;
}

const ForecastingSearchMenu: React.FC<ISearchMenuProps> = ({
  productCodes,
  setProductCodes,
  storeOptions,
  stores,
  setStores,
  daysCover,
  setDaysCover,
  repRequired,
  setRepRequired,
  storeTier,
  setstoreTier,
  selectedDate,
  handleSubmit
}) => {
  const daysCoverFilter = [
    { name: 'All', value: '' },
    { name: '≤ 1', value: '1' },
    { name: '≤ 2', value: '2' },
    { name: '≤ 5', value: '5' },
    { name: '≤ 10', value: '10' },
    { name: '≤ 25', value: '25' }
  ];

  const topStoreOptions = [
    { name: 'All', value: '' },
    { name: '★', value: '1' },
    { name: '★★', value: '2' },
    { name: '★★★', value: '3' }
  ];

  const clearSearchOptions = () => {
    setDaysCover('');
    setProductCodes([]);
    setStores([]);
    setstoreTier('');
  };

  return (
    <StyledSearchMenu>
      <Container fixed>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <br />
          <div className='search-menu-row'>
            <ProductCodeSearch
              productCodes={productCodes}
              setProductCodes={setProductCodes}
              style={{ width: '30%' }}
            />
            <AutoCompleteSearch
              id='store-search'
              label='Store'
              options={storeOptions}
              searchTerms={stores}
              setSearchTerms={setStores}
              style={{ width: '30%' }}
              placeholder='Type store name here'
            />
            <Label
              id='top-30-stores'
              control={
                <StyledFormControl variant={'outlined' as any}>
                  <Select
                    data-testid='select-testid'
                    displayEmpty
                    value={storeTier}
                    onChange={(event: any) => setstoreTier(event.target.value)}
                  >
                    <MenuItem key='placeholder' value='' disabled>
                      Select value
                    </MenuItem>
                    {topStoreOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              }
              label='Store Tier'
              classes='label--w-15'
            />
            <Label
              id='days-cover'
              control={
                <StyledFormControl variant={'outlined' as any}>
                  <Select
                    data-testid='select-testid'
                    displayEmpty
                    value={daysCover}
                    onChange={(event: any) => setDaysCover(event.target.value)}
                  >
                    <MenuItem key='placeholder' value='' disabled>
                      Select value
                    </MenuItem>
                    {daysCoverFilter.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              }
              label='Days Cover'
              classes='label--w-15'
            />
          </div>
          <hr />
          <div style={{ textAlign: 'right' }}>
            <StyledFormControl variant={'outlined' as any}>
              <ControlledCheckbox
                label='Replen Required'
                checked={repRequired}
                handleChange={(event: any) =>
                  setRepRequired(event.target.checked)
                }
              />
            </StyledFormControl>
          </div>
          <div className='search-menu-row'>
            <CustomButton
              type='button'
              classes='btn--w-200-px btn--light-grey clear-btn'
              title='Clear'
              handleClick={() => clearSearchOptions()}
            />
            <CustomButton
              type='submit'
              classes='btn--w-200-px'
              title='Search'
              disabled={selectedDate === undefined}
            />
          </div>
        </form>
      </Container>
    </StyledSearchMenu>
  );
};

export default ForecastingSearchMenu;
