import React, { useRef, useState, useEffect } from 'react';
import {
  Chip,
  MenuItem,
  Button,
  Select,
  SelectChangeEvent
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import _ from 'lodash';

import StyledSkuGroupSearch from './styles';
import ViewMoreIcon from '../../assets/icons/view-more.svg';
import StyledFormControl from '../ControlledSelect/styles';
import Label from '../Label';
import environment from '../../environment';
import { ISkuGroup } from '../../interfaces/ISkuGroup';

interface ISkuGroupSearchProps {
  productCodes: string[];
  setProductCodes: CallableFunction;
  skuGroupNames: string[];
  setSkuGroupNames: CallableFunction;
  classes?: string;
  style?: any;
}

const SkuGroupSearch: React.FC<ISkuGroupSearchProps> = ({
  productCodes,
  setProductCodes,
  skuGroupNames,
  setSkuGroupNames,
  classes,
  style
}) => {
  const [expanded, setExpanded] = useState(false);
  const [viewMoreBtnVisible, setViewMoreBtnVisible] = useState(false);
  const overflowingText = useRef<HTMLDivElement | null>(null);
  const [skuGroups, setSkuGroups] = useState<ISkuGroup[]>([]);
  const [addedGroups, setAddedGroups] = useState<ISkuGroup[]>([]);

  const checkProductCodesOverflow = (container: HTMLDivElement | null) => {
    if (container) {
      if (expanded) {
        setViewMoreBtnVisible(container.offsetHeight > 100);
      } else {
        setViewMoreBtnVisible(container.offsetHeight < container.scrollHeight);
      }
    }
  };

  const handleSkuGroupSelection = (event: SelectChangeEvent<'' | null>) => {
    const group: any = event.target.value;

    const { skuGroup } = group;
    const { products } = group;

    if (!addedGroups.includes(group)) setAddedGroups([...addedGroups, group]);

    if (!skuGroupNames.includes(skuGroup)) {
      setSkuGroupNames([...skuGroupNames, skuGroup]);

      setProductCodes([
        ...productCodes,
        ...products.filter((product: string) => !productCodes.includes(product))
      ]);
    }
  };

  const removeSkuGroupChip = (groupName: string) => {
    setSkuGroupNames(skuGroupNames.filter((item) => item !== groupName));

    const removedProducts: string[] | undefined = _.clone(
      addedGroups.find((item: ISkuGroup) => item.skuGroup === groupName)
        ?.products
    );

    if (removedProducts) {
      for (let i = 0; i <= addedGroups.length - 1; i += 1) {
        if (addedGroups[i].skuGroup !== groupName) {
          for (let j = removedProducts.length - 1; j >= 0; j -= 1) {
            if (addedGroups[i].products.includes(removedProducts[j])) {
              removedProducts.splice(j, 1);
            }
          }
        }
      }
    }

    setAddedGroups(
      addedGroups.filter((group: ISkuGroup) => group.skuGroup !== groupName)
    );

    setProductCodes(
      productCodes.filter(
        (product: string) => !removedProducts?.includes(product)
      )
    );
  };

  useEffect(() => {
    checkProductCodesOverflow(overflowingText.current);
  }, [skuGroupNames]);

  useEffect(() => {
    axios
      .get(`${environment.apiPath}skuGroup`, {
        ...environment.params
      })
      .then(
        (res) => {
          setSkuGroups(res.data);
        },
        (err) => {
          toast.error(err.message);
        }
      );
  }, []);

  return (
    <StyledSkuGroupSearch expanded={expanded} style={style}>
      <div className='sku-group-search'>
        <div className='sku-group__input'>
          <Label
            id='search-query'
            control={
              <StyledFormControl variant={'outlined' as any}>
                <Select
                  data-testid='select-query-testid'
                  displayEmpty
                  value=''
                  onChange={handleSkuGroupSelection}
                >
                  <MenuItem key='placeholder' value='' disabled>
                    Select SKU group
                  </MenuItem>
                  {skuGroups.map((group: any) => (
                    <MenuItem key={group.id} value={group}>
                      {group.skuGroup}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            }
            label='SKU group'
            classes='label--w-45'
          />
        </div>
      </div>
      <div ref={overflowingText} className={`sku-group-container ${classes}`}>
        {skuGroupNames.map((group: string) => (
          <Chip
            key={group}
            label={group}
            onDelete={() => removeSkuGroupChip(group)}
          />
        ))}
      </div>
      {viewMoreBtnVisible && (
        <Button
          onClick={() => setExpanded(!expanded)}
          className={`view-more-button ${classes}`}
        >
          <span style={{ color: 'white' }}>
            {expanded ? 'View less' : 'View more'}
          </span>
          <img src={ViewMoreIcon} alt='ViewMore' />
        </Button>
      )}
    </StyledSkuGroupSearch>
  );
};

SkuGroupSearch.defaultProps = {
  classes: '',
  style: null
};

export default SkuGroupSearch;
