import React, { useState, SyntheticEvent, useEffect } from 'react';
import { Container } from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

import StyledSearchBar from './styles';
import CustomButton from '../CustomButton';
import { IGenericEntry } from '../../interfaces/IGenericSection';
import environment from '../../environment';
import LoadingScreen from '../LoadingScreen';
import ControlledInput from '../ControlledInput';
import ControlledSelect from '../ControlledSelect';

export interface ISearchBarProps {
  searchOptions: IGenericEntry[];
  buttonTitle: string;
  path: string;
  handleSuccess: CallableFunction;
  clearData: CallableFunction;
}

const SearchBar: React.FC<ISearchBarProps> = ({
  searchOptions,
  buttonTitle,
  path,
  handleSuccess,
  clearData
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchOption, setSearchOption] = useState<IGenericEntry>(
    searchOptions[0]
  );
  const [searchValue, setSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const getPaginatedElements = (
    option: string,
    value: string,
    lastEvaluatedOrder: string | undefined = undefined,
    results: Array<string> = []
  ) => {
    axios
      .get(`${environment.apiPath}${path}?${option}=${value}`, {
        ...{
          params: {
            pageSize: 500,
            lastEvaluatedOrder
          }
        },
        ...environment.params
      })
      .then(
        (res) => {
          if (!res.data.length) {
            handleSuccess(results);
            setLoading(false);
          } else {
            results.push(...res.data);
            getPaginatedElements(
              option,
              value,
              res.data[res.data.length - 1].orderId,
              results
            );
          }
        },
        (err) => {
          toast.error(err.message);
          setLoading(false);
        }
      );
  };

  const getElements = (option: string, value: string) => {
    if (option === 'customerId') {
      getPaginatedElements(option, value);
    } else {
      axios
        .get(`${environment.apiPath}${path}?${option}=${value}`, {
          ...environment.params
        })
        .then(
          (res) => {
            handleSuccess(res.data);
            setLoading(false);
          },
          (err) => {
            toast.error(err.message);
            setLoading(false);
          }
        );
    }
  };

  useEffect(() => {
    if (location.search) {
      const searchElements = location.search.split(/[?&=]/);
      const option = searchOptions.find(
        (opt: IGenericEntry) => opt.code === searchElements[1]
      );

      if (option) {
        setSearchOption(option);
      }
      setSearchValue(searchElements[2]);
      clearData();
      setLoading(true);

      getElements(searchElements[1], searchElements[2]);
    }
  }, [location]);

  const handleSearchOptionChange = (event: any) => {
    setSearchOption(event.target.value);
  };

  const handleSearchValueChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    navigate(`${location.pathname}?${searchOption.code}=${searchValue}`);
  };

  return (
    <>
      <StyledSearchBar>
        <Container fixed>
          <form noValidate autoComplete='off' onSubmit={handleSubmit}>
            <ControlledSelect
              id='search-option'
              value={searchOption}
              handleChange={handleSearchOptionChange}
              label='Search option'
              options={searchOptions}
              classes='label--w-20'
            />
            <ControlledInput
              id='search-value'
              placeholder={`Type ${searchOption.value.toLowerCase()} here`}
              value={searchValue}
              handleChange={handleSearchValueChange}
              label={searchOption.value}
              type='text'
              classes='label--w-60'
            />
            <CustomButton
              type='submit'
              disabled={!searchValue}
              classes='btn--w-20'
              title={buttonTitle}
            />
          </form>
        </Container>
      </StyledSearchBar>
      {loading && <LoadingScreen />}
    </>
  );
};

export default SearchBar;
