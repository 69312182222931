import React, { ChangeEvent, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { GridApi, GridReadyEvent } from 'ag-grid-community';

import HeroProducts from '../../assets/heros/hero-products.png';
import HeroBanner from '../../components/HeroBanner';
import environment from '../../environment';
import StyledConfiguration from './styles';
import ControlledInput from '../../components/ControlledInput';
import CustomButton from '../../components/CustomButton';
import DownloadIcon from '../../assets/icons/download.svg';

const HierarchyDeliveryMapping: React.FC = () => {
  const [rowData, setRowData] = useState([]);

  const [gridApi, setGridApi] = useState<GridApi>();
  const [quickFilter, setQuickFilter] = useState<string>('');

  const getRowData = (gridApiParam: GridApi) => {
    axios
      .get(`${environment.apiPath}getConfigItems?page=hierarchy`, {
        ...environment.params
      })
      .then(
        (res) => {
          setRowData(res.data);
          if (res.data.length === 0) {
            gridApiParam?.showNoRowsOverlay();
          }
        },
        (err) => {
          setRowData([]);
          toast.error(err.message);
          gridApiParam?.showNoRowsOverlay();
        }
      );
  };

  const handleDataExport = () => {
    gridApi?.exportDataAsExcel({
      fileName: 'HierarchyDeliveryMapping.xlsx'
    });
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api?.showLoadingOverlay();
    setGridApi(params.api);
    getRowData(params.api);
  };

  const columnDefs = [
    { headerName: 'Hierarchy', field: 'hierarchy', maxWidth: 300 },
    { headerName: 'Hierarchy Name', field: 'hierarchyName', maxWidth: 300 },
    { headerName: 'Target Service', field: 'targetService' },
    { headerName: 'Service', field: 'service' },
    { headerName: 'Delivery Method', field: 'deliveryMethod' }
  ];

  return (
    <>
      <HeroBanner
        title='Hierarchy Delivery Mapping'
        background={HeroProducts}
      />
      <StyledConfiguration fixed>
        <div className='configuration__actions'>
          <ControlledInput
            id='quick-filter'
            placeholder='Filter any column'
            value={quickFilter}
            handleChange={(event: ChangeEvent<HTMLInputElement>) => {
              setQuickFilter(event.target.value);
              gridApi?.setQuickFilter(event.target.value);
            }}
            label='Grid filter'
            type='text'
            classes='label--w-30'
          />
          <div>
            <CustomButton
              type='button'
              classes='btn--w-200-px btn--black'
              title='Export'
              endIcon={<img src={DownloadIcon} alt='Download configuration' />}
              handleClick={handleDataExport}
            />
          </div>
        </div>
        <div className='configuration__grid ag-theme-balham'>
          <AgGridReact
            rowHeight={30}
            defaultColDef={{
              resizable: true,
              flex: 1,
              minWidth: 100,
              filter: 'agTextColumnFilter'
            }}
            enableCellTextSelection
            suppressRowClickSelection
            pagination
            rowData={rowData}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            rowGroupPanelShow='always'
            suppressDragLeaveHidesColumns
          />
        </div>
      </StyledConfiguration>
    </>
  );
};

export default HierarchyDeliveryMapping;
