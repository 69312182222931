import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  GridApi,
  NewValueParams,
  ICellRendererParams
} from 'ag-grid-community';
import { Button } from '@mui/material';

import StyledReplenChangeHeader from './styles';
import environment from '../../../../environment';
import CustomButton from '../../../../components/CustomButton';
import Disable from '../../../../assets/icons/disable.svg';

interface ICustomRemoveCellRendererParams extends ICellRendererParams {
  gridApi: GridApi;
  replenChanges: IReplenChanges[];
  setReplenChanges: CallableFunction;
}

interface IReplenChanges {
  id: number;
  productCode: string;
  productName: string;
  storeCode: string;
  storeName: string;
  replenishment: number;
  actualReplenishment: number;
  oldActual: number;
}

interface IReplenChangesProps {
  replenChanges: IReplenChanges[];
  setReplenChanges: CallableFunction;
  gridApi: GridApi | undefined;
}

const ReplenChanges: React.FC<IReplenChangesProps> = ({
  replenChanges,
  setReplenChanges,
  gridApi
}) => {
  const handleSaveChanges = () => {
    const body = replenChanges
      .filter((obj) => obj.replenishment !== obj.actualReplenishment)
      .map((obj) => ({
        id: obj.id,
        replenishment: obj.replenishment,
        actualReplenishment: obj.actualReplenishment
      }));
    axios
      .post(`${environment.apiPathForecasting}updateActualReplenishment`, body)
      .then(() => {
        toast.success('Actual Replenishment Values Updated');
        setReplenChanges([]);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const clearReplenChanges = () => {
    if (gridApi) {
      replenChanges.forEach((obj) => {
        const rowDataNode = gridApi.getRowNode(obj.id.toString());
        if (rowDataNode) {
          const transaction = {
            update: [
              { ...rowDataNode.data, actualReplenishment: obj.oldActual }
            ]
          };
          gridApi.applyTransaction(transaction);
        }
      });

      setReplenChanges([]);
    }
  };

  const removeReplenChange = (params: ICustomRemoveCellRendererParams) => {
    const rowDataNode = params.gridApi.getRowNode(params.data.id);
    if (rowDataNode) {
      const transaction = {
        update: [
          {
            ...rowDataNode.data,
            actualReplenishment: params.data.oldActual
          }
        ]
      };
      params.gridApi.applyTransaction(transaction);

      params.setReplenChanges(
        params.replenChanges.filter((obj) => obj.id !== params.data.id)
      );
    }
  };

  const handleReplenChange = (params: NewValueParams) => {
    if (gridApi) {
      const rowDataNode = gridApi.getRowNode(params.data.id);
      if (rowDataNode) {
        const transaction = {
          update: [
            {
              ...rowDataNode.data,
              id: params.data.id,
              actualReplenishment: params.newValue
            }
          ]
        };
        gridApi.applyTransaction(transaction);
      }
    }
  };

  const renderRemoveButton = (params: ICustomRemoveCellRendererParams) => {
    const cell = (
      <div className='cell__actions'>
        <Button
          type='button'
          onClick={() => {
            removeReplenChange(params);
          }}
        >
          <img src={Disable} alt='Remove replenishment change' />
        </Button>
      </div>
    );

    return cell;
  };
  const columnDefs: any = [
    {
      headerName: '',
      enableRowGroup: true,
      sortable: true,
      width: 75,
      cellRenderer: 'renderRemoveButton',
      cellRendererParams: {
        gridApi: gridApi,
        replenChanges: replenChanges,
        setReplenChanges: setReplenChanges
      }
    },
    {
      headerName: 'Rec Replen',
      field: 'replenishment',
      enableRowGroup: true,
      sortable: true
    },
    {
      headerName: 'Act Replen',
      field: 'actualReplenishment',
      enableRowGroup: true,
      onCellValueChanged: handleReplenChange,
      sortable: true,
      editable: true
    },
    {
      headerName: 'Product Code',
      field: 'productCode',
      enableRowGroup: true,
      sortable: true,
      flex: 1,
      minWidth: 150
    },
    {
      headerName: 'Product Name',
      field: 'productName',
      sortable: true,
      enableRowGroup: true,
      flex: 2
    },
    {
      headerName: 'Store Code',
      field: 'storeCode',
      sortable: true,
      enableRowGroup: true,
      flex: 3
    },
    {
      headerName: 'Store Name',
      field: 'storeName',
      sortable: true,
      enableRowGroup: true,
      flex: 4
    }
  ];

  return (
    <StyledReplenChangeHeader fixed>
      <div className='changes-header-row'>
        <h2 className='changes-title'>Recommended Replenishment Overrides</h2>
        <div className='changes-report__actions'>
          <CustomButton
            type='button'
            classes='btn--w-200-px btn--black'
            title='Clear Table'
            handleClick={() => {
              clearReplenChanges();
            }}
          />
          <CustomButton
            type='button'
            classes='btn--w-200-px btn--blue'
            title='Save Changes'
            disabled={replenChanges.length === 0}
            handleClick={handleSaveChanges}
          />
        </div>
      </div>
      <div className='change_report report__grid ag-theme-balham'>
        <AgGridReact
          rowHeight={30}
          pagination
          defaultColDef={{
            resizable: true,
            sortable: true
          }}
          enableCellTextSelection
          suppressDragLeaveHidesColumns
          suppressRowClickSelection
          rowData={replenChanges}
          // getRowId={(params) => params.data.id}
          excelStyles={[
            {
              id: 'numberType',
              dataType: 'Number'
            }
          ]}
          components={{ renderRemoveButton: renderRemoveButton }}
          columnDefs={columnDefs}
        />
      </div>
    </StyledReplenChangeHeader>
  );
};

export default ReplenChanges;
