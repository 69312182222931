import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  GridApi,
  ICellRendererParams,
  NewValueParams
} from 'ag-grid-community';
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { Button } from '@mui/material';

import StyledStoreConfigHeader from './styles';
import environment from '../../../../../environment';
import CustomButton from '../../../../../components/CustomButton';
import Disable from '../../../../../assets/icons/disable.svg';
import { ITierChanges } from '../../StoreConfig';

interface ICustomRemoveCellRendererParams extends ICellRendererParams {
  gridApi: GridApi;
  tierChanges: ITierChanges[];
  setTierChanges: CallableFunction;
}

interface IStoreConfigHeaderProps {
  tierChanges: ITierChanges[];
  setTierChanges: CallableFunction;
  renderStars: (params: ICellRendererParams) => React.JSX.Element;
  gridApi: GridApi | undefined;
}

const StoreConfigHeader: React.FC<IStoreConfigHeaderProps> = ({
  tierChanges,
  setTierChanges,
  renderStars,
  gridApi
}) => {
  const handleSaveChanges = () => {
    const body = tierChanges
      .filter((obj) => obj.originalTier !== obj.newTier)
      .map((obj) => ({
        id: obj.id,
        tier: obj.newTier
      }));
    console.log(body);
    axios
      .post(`${environment.apiPathForecasting}updateStoreTier`, body)
      .then(() => {
        toast.success('Store Tiers successfully updated');
        setTierChanges([]);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const clearTierChanges = () => {
    if (gridApi) {
      tierChanges.forEach((obj) => {
        const rowDataNode = gridApi.getRowNode(obj.id);
        if (rowDataNode) {
          const transaction = {
            update: [{ ...rowDataNode.data, tier: obj.originalTier }]
          };
          gridApi.applyTransaction(transaction);
        }
      });

      setTierChanges([]);
    }
  };

  const removeTierChange = (params: ICustomRemoveCellRendererParams) => {
    const rowDataNode = params.gridApi.getRowNode(params.data.id);

    if (rowDataNode) {
      const transaction = {
        update: [
          {
            ...rowDataNode.data,
            tier: params.data.originalTier
          }
        ]
      };
      params.gridApi.applyTransaction(transaction);

      params.setTierChanges(
        params.tierChanges.filter((obj) => obj.id !== params.data.id)
      );
    }
  };

  const handleNewTierChange = (params: NewValueParams) => {
    if (gridApi) {
      const rowDataNode = gridApi.getRowNode(params.data.id);
      if (rowDataNode) {
        const transaction = {
          update: [
            {
              ...rowDataNode.data,
              tier: params.data.newTier
            }
          ]
        };
        gridApi.applyTransaction(transaction);
      }
    }
  };

  const renderRemoveButton = (params: ICustomRemoveCellRendererParams) => {
    const cell = (
      <div className='cell__actions'>
        <Button
          type='button'
          onClick={() => {
            removeTierChange(params);
          }}
        >
          <img src={Disable} alt='Remove tier change' />
        </Button>
      </div>
    );

    return cell;
  };
  const columnDefs: any = [
    {
      headerName: '',
      enableRowGroup: true,
      sortable: true,
      width: 50,
      cellRenderer: 'RenderRemoveButton',
      cellRendererParams: {
        gridApi: gridApi,
        tierChanges: tierChanges,
        setTierChanges: setTierChanges
      }
    },
    {
      headerName: 'Old Tier',
      field: 'originalTier',
      enableRowGroup: true,
      sortable: true,
      cellRenderer: 'RenderStars',
      cellRendererParams: { readOnly: true, classes: 'btn--light-red' }
    },
    {
      headerName: 'New Tier',
      field: 'newTier',
      enableRowGroup: true,
      onCellValueChanged: handleNewTierChange,
      sortable: true,
      cellRenderer: 'RenderStars',
      cellRendererParams: { readOnly: false, classes: 'btn--light-blue' }
    },
    {
      headerName: 'Store Code',
      field: 'id',
      enableRowGroup: true,
      sortable: true,
      flex: 1
    },
    {
      headerName: 'Store Name',
      field: 'name',
      sortable: true,
      enableRowGroup: true,
      flex: 2
    }
  ];

  return (
    <StyledStoreConfigHeader fixed>
      <div className='changes-header-row'>
        <h2 className='changes-title'>Tier Updates</h2>
        <div className='changes-report__actions'>
          <CustomButton
            type='button'
            classes='btn--w-200-px btn--black'
            title='Clear Table'
            handleClick={() => {
              clearTierChanges();
            }}
          />
          <CustomButton
            type='button'
            classes='btn--w-200-px btn--blue'
            title='Save Changes'
            disabled={tierChanges.length === 0}
            handleClick={handleSaveChanges}
          />
        </div>
      </div>
      <div className='change_report report__grid ag-theme-balham'>
        <AgGridReact
          rowHeight={30}
          pagination
          defaultColDef={{
            resizable: true,
            sortable: true
          }}
          paginationPageSize={5}
          enableCellTextSelection
          suppressDragLeaveHidesColumns
          suppressRowClickSelection
          rowData={tierChanges}
          components={{
            RenderRemoveButton: renderRemoveButton,
            RenderStars: renderStars
          }}
          getRowId={(params) => params.data.id}
          excelStyles={[
            {
              id: 'stringType',
              dataType: 'String'
            }
          ]}
          columnDefs={columnDefs}
        />
      </div>
    </StyledStoreConfigHeader>
  );
};

export default StoreConfigHeader;
