import React, { useState, SyntheticEvent, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { GridApi } from 'ag-grid-community';

import environment from '../../../../../environment';
import EditIcon from '../../../../../assets/icons/edit.svg';
import {
  ISkuGroup,
  ISkuGroupProduct
} from '../../../../../interfaces/ISkuGroup';
import GenericModal from '../../../../../components/GenericModal';
import ProductCodeSearch from '../../../../../components/ProductCodeSearch';

interface IManageProductsProps {
  id: string;
  skuGroup: string;
  gridApi: GridApi | undefined;
  rowProductsData: ISkuGroupProduct[];
  setProductsRowData: any;
  rowData: ISkuGroup[];
  setRowData: any;
  gridLoading: boolean;
}

const ManageProductsModal: React.FC<IManageProductsProps> = ({
  id,
  skuGroup,
  gridApi,
  rowProductsData,
  setProductsRowData,
  rowData,
  setRowData,
  gridLoading
}) => {
  const [productCodes, setProductCodes] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setProductCodes(
      rowProductsData.map((product: ISkuGroupProduct) => product.productCode)
    );
  }, [gridLoading]);

  const clearForm = () => {
    setProductCodes(
      rowProductsData.map((product: ISkuGroupProduct) => product.productCode)
    );
    setErrorMessage('');
  };

  const handleSubmit =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);

      axios
        .put(
          `${environment.apiPath}skuGroup/${id}`,
          { products: productCodes },
          {
            ...environment.params
          }
        )
        .then(
          (res) => {
            setProductsRowData(res.data.products);

            setRowData(
              rowData.map((row: ISkuGroup) =>
                row.id === id
                  ? {
                      ...row,
                      lastUpdateBy: res.data.lastUpdateBy,
                      lastUpdateDate: res.data.lastUpdateDate
                    }
                  : row
              )
            );

            gridApi?.refreshCells({ rowNodes: res.data.products });
            toast.success(`Products in group ${skuGroup} successfully updated`);
          },
          (err) => {
            if (err.response) {
              toast.error(err.response.data.error);
            } else {
              toast.error(err.message);
            }
            clearForm();
          }
        )
        .finally(() => {
          setOpen(false);
          setLoading(false);
        });
    };

  return (
    <GenericModal
      btnTitle='Manage products'
      classes='btn--small btn--blue'
      endIcon={<img src={EditIcon} alt='Edit' />}
      disabled={gridLoading}
      clearForm={clearForm}
      formTitle='Manage Products'
      formSubtitle={<h3>{skuGroup}</h3>}
      handleSubmit={handleSubmit}
    >
      <ProductCodeSearch
        productCodes={productCodes}
        setProductCodes={setProductCodes}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        maxLen={1399}
        classes='product-codes--ml-50 product-codes--justify-center'
      />
    </GenericModal>
  );
};

export default ManageProductsModal;
