import React, { useState, useEffect, SyntheticEvent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import _ from 'lodash';
import {
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  NewValueParams
} from 'ag-grid-community';
import { Tooltip } from '@mui/material';

import HeroProducts from '../../assets/heros/hero-products.png';
import HeroBanner from '../../components/HeroBanner';
import StyledReport from './styles';
import environment from '../../environment';
import ForecastingSearchMenu from './components/ForecastingSearchMenu/ForecastingSearchMenu';
import ControlledInput from '../../components/ControlledInput/ControlledInput';
import CustomButton from '../../components/CustomButton';
import DownloadIcon from '../../assets/icons/download.svg';
import StarRating from './components/StarRating';
import ReplenChanges from './components/ReplenChanges/ReplenChanges';
export interface ICustomCellRendererParams extends ICellRendererParams {
  readOnly?: boolean;
  classes?: string;
}

interface IForecasting {
  productCode: string;
  storeCode: string;
  productName: string;
  storeName: string;
  soh: number;
  totalSales: number;
  averageSales: number;
  lastDaysSales: number;
  availableToPromise: number;
  inTransit: number;
  daysCover: number;
  replenishment: number;
  actualReplenishment: number;
  dssc: number;
  multichannel: number;
  sgnPot: number;
  tier: number;
  totalReplenishment: number;
}

interface IReplenChanges {
  id: number;
  productCode: string;
  productName: string;
  storeCode: string;
  storeName: string;
  replenishment: number;
  actualReplenishment: number;
  oldActual: number;
}

interface IStoreObjects {
  id: string;
  name: string;
  compositeName: string;
}

const Forecasting: React.FC = () => {
  const [rowData, setRowData] = useState<IForecasting[]>([]);
  const [productCodes, setProductCodes] = useState<string[]>([]);
  const [storeOptions, setStoreOptions] = useState<string[]>([]);
  const [daysCover, setDaysCover] = useState<string>('');
  const [repRequired, setRepRequired] = useState<boolean>(false);
  const [stores, setStores] = useState<string[]>([]);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [quickFilter, setQuickFilter] = useState<string>('');
  const [storeTier, setstoreTier] = useState<string>('');
  const [replenChanges, setReplenChanges] = useState<IReplenChanges[]>([]);
  const [storeObjects, setStoreObjects] = useState<IStoreObjects[]>([]);
  const [forecastDates, setForecastDates] = useState<Date[]>([]);
  const [requestId, setRequestId] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [exportStatus, setExportStatus] = useState<string>('');

  const getRowData = () => {
    let rows: any = [];
    let requestsWaiting = 1;

    const queryData = (nextOffset = '') => {
      gridApi?.showLoadingOverlay();
      const storeNames: string[] = stores.map((compositeName: string) => {
        const matchingObject: any | undefined = storeObjects.find(
          (obj: any) => obj.compositeName === compositeName
        );

        return matchingObject?.name || -1;
      });
      axios
        .get(
          `${
            environment.apiPathForecasting
          }getForecastingItems?pageSize=30000&nextOffset=${nextOffset}&productCodes=${productCodes.join(
            ','
          )}&stores=${storeNames.join(
            ','
          )}&daysCover=${daysCover}&storeTier=${storeTier}&selectedDate=${
            selectedDate
              ? `${selectedDate.getFullYear()}-${
                  selectedDate.getMonth() + 1
                }-${selectedDate.getDate()}`
              : ''
          }&repRequired=${repRequired}`,
          { ...environment.paramsForecasting }
        )
        .then(
          (res) => {
            rows = rows.concat(
              res.data.rows.map((row: any[]) =>
                _.zipObject(res.data.columns, row)
              )
            );
            if (res.data.nextOffsets.length > 0) {
              requestsWaiting = res.data.nextOffsets.length;
              res.data.nextOffsets.map((token: any) => queryData(token.offset));
            } else {
              requestsWaiting -= 1;
            }
            if (requestsWaiting === 0) {
              setRowData(rows);
              console.log(rows);
              if (rows.length === 0) {
                gridApi?.showNoRowsOverlay();
              }
            }
          },
          (err) => {
            setRowData([]);
            gridApi?.showNoRowsOverlay();
            toast.error(err.message);
          }
        );
    };
    queryData();
  };

  const getStores = () => {
    axios.get(`${environment.apiPathForecasting}getAllStores`).then(
      (res) => {
        const arrayOfNames: string[] = res.data.body.map(
          (obj: any) => obj.compositeName
        );
        setStoreOptions(arrayOfNames);
        setStoreObjects(res.data.body);
      },
      (err) => {
        toast.error(err.message);
      }
    );
  };

  const getBulkOrderFile = () => {
    axios
      .get(
        `${environment.apiPathForecasting}getBulkOrderFile?id=${requestId}`,
        {
          responseType: 'json',
          ...environment.params
        }
      )
      .then(
        (res: any) => {
          setExportStatus('Download');
          console.log(res.data.body);
          if (res.data.body.status === 'pending') {
            setRequestId(res.data.body.id);
            toast.warn('Generating...');
          }
          const downloadURL = () => {
            toast.success('Downloading now...');
            const link = document.createElement('a');
            link.href = res.data.body.s3Link;
            link.download = 'BulkOrderTemplate.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          };
          if (res.data.body.status === 'complete') {
            downloadURL();
          }
        },
        (err) => toast.error(err.response.data.error)
      );
  };

  const getForecastDates = () => {
    axios.get(`${environment.apiPathForecasting}getAllForecastDates`).then(
      (res: any) => {
        setForecastDates(res.data.body.map((date: string) => new Date(date)));
        setSelectedDate(new Date(res.data.body.slice(-1)));
      },
      (err) => {
        toast.error(err.message);
      }
    );
  };

  useEffect(() => {
    getStores();
    getForecastDates();
  }, []);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setExportStatus('Export');
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    gridApi?.showLoadingOverlay();
    getRowData();
  };

  const handleCellValueChanged = (params: NewValueParams) => {
    const prevReplenChanges = replenChanges.find(
      (obj) => obj.id === params.data.id
    );
    if (prevReplenChanges) {
      setReplenChanges(
        replenChanges.map((obj) =>
          obj.id === params.data.id
            ? {
                ...obj,
                actualReplenishment: params.newValue
              }
            : obj
        )
      );
    } else {
      setReplenChanges([
        ...replenChanges,
        {
          id: params.data.id,
          productCode: params.data.productCode,
          productName: params.data.productName,
          storeCode: params.data.storeCode,
          storeName: params.data.storeName,
          replenishment: params.data.replenishment,
          actualReplenishment: params.newValue,
          oldActual: params.oldValue
        }
      ]);
    }
  };

  const daysCoverCellClass = (params: any) => {
    if (params.value === undefined) {
      return '';
    }
    if (params.value < 2.0) {
      return 'report-cell-red';
    }
    if (params.value < 4.0) {
      return 'report-cell-amber';
    }

    return 'report-cell-green';
  };

  const lastUpdatedCellClass = (date: Date) => {
    const today = new Date();
    // Sets today as yesterday and resets the time to 00:00:00
    today.setDate(today.getDate() - 1);
    today.setHours(0, 0, 0, 0);

    if (moment(date).isSame(today, 'day')) {
      return 'last-update-green';
    }
    if (date < today) {
      return 'last-update-amber';
    }

    return 'last-update-red';
  };

  const timeDiff = (date: Date) => {
    const today = new Date();
    // Sets today as yesterday and resets the time to 00:00:00
    today.setDate(today.getDate() - 1);
    today.setHours(0, 0, 0, 0);

    return Math.round((today.getTime() - date.getTime()) / (1000 * 3600 * 24));
  };

  const renderStars = (params: ICustomCellRendererParams) => {
    const cell = (
      <div>
        <StarRating label='top-store-rating' params={params} />
      </div>
    );

    return cell;
  };

  const columnDefs: any = [
    {
      headerName: 'DSSC',
      field: 'dssc',
      enableRowGroup: true,
      sortable: true,
      pinned: 'left',
      maxWidth: 70
    },
    {
      headerName: 'MC',
      field: 'multichannel',
      enableRowGroup: true,
      sortable: true,
      pinned: 'left',
      maxWidth: 60
    },
    {
      headerName: 'SGN',
      field: 'sgnPot',
      enableRowGroup: true,
      sortable: true,
      pinned: 'left',
      maxWidth: 60
    },
    {
      headerName: 'Total Replen',
      field: 'totalReplenishment',
      enableRowGroup: true,
      sortable: true,
      pinned: 'left',
      maxWidth: 85,
      wrapText: true,
      headerClass: 'wrap-text'
    },
    {
      headerName: 'SKU',
      field: 'productCode',
      enableRowGroup: true,
      sortable: true,
      pinned: 'left',
      width: 68
    },
    {
      headerName: 'Description',
      field: 'productName',
      minWidth: 110,
      sortable: true,
      enableRowGroup: true
    },
    {
      headerName: 'Store Code',
      field: 'storeCode',
      enableRowGroup: true,
      sortable: true,
      minWidth: 80,
      hide: true
    },
    {
      headerName: 'Store Name',
      field: 'storeName',
      minWidth: 110,
      enableRowGroup: true,
      sortable: true
    },
    {
      headerName: 'Tier',
      field: 'tier',
      enableRowGroup: true,
      sortable: true,
      minWidth: 95,
      cellRenderer: 'renderStars',
      cellRendererParams: { readOnly: true }
    },
    {
      headerName: 'SOH',
      field: 'soh',
      enableRowGroup: true,
      sortable: true,
      minWidth: 65
    },
    {
      headerName: 'Sold Today',
      field: 'totalSales',
      enableRowGroup: true,
      sortable: true,
      minWidth: 80,
      wrapText: true,
      headerClass: 'wrap-text'
    },
    {
      headerName: 'Weekly Avg',
      field: 'averageSales',
      enableRowGroup: true,
      sortable: true,
      minWidth: 80,
      wrapText: true,
      headerClass: 'wrap-text'
    },
    {
      headerName: 'Past 7 Days',
      field: 'lastDaysSales',
      enableRowGroup: true,
      sortable: true,
      minWidth: 80,
      wrapText: true,
      headerClass: 'wrap-text'
    },
    {
      headerName: 'ATP',
      field: 'availableToPromise',
      enableRowGroup: true,
      sortable: true,
      minWidth: 60
    },
    {
      headerName: 'In Transit',
      field: 'inTransit',
      enableRowGroup: true,
      sortable: true,
      minWidth: 80,
      wrapText: true,
      headerClass: 'wrap-text'
    },
    {
      headerName: 'Days Cover',
      field: 'daysCover',
      enableRowGroup: true,
      sortable: true,
      cellClass: daysCoverCellClass,
      minWidth: 80,
      wrapText: true,
      headerClass: 'wrap-text'
    },
    {
      headerName: 'Rec Replen',
      field: 'replenishment',
      enableRowGroup: true,
      sortable: true,
      minWidth: 80,
      wrapText: true,
      headerClass: 'wrap-text'
    },
    {
      headerName: 'Act Replen',
      field: 'actualReplenishment',
      enableRowGroup: true,
      sortable: true,
      editable: true,
      onCellValueChanged: handleCellValueChanged,
      minWidth: 80,
      wrapText: true,
      headerClass: 'wrap-text'
    }
  ];

  return (
    <>
      <HeroBanner title='Forecasting' background={HeroProducts} />
      <ForecastingSearchMenu
        productCodes={productCodes}
        setProductCodes={setProductCodes}
        storeOptions={storeOptions}
        stores={stores}
        repRequired={repRequired}
        setRepRequired={setRepRequired}
        setStores={setStores}
        storeTier={storeTier}
        setstoreTier={setstoreTier}
        daysCover={daysCover}
        setDaysCover={setDaysCover}
        handleSubmit={handleSubmit}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        forecastDates={forecastDates}
      />
      <ReplenChanges
        replenChanges={replenChanges}
        setReplenChanges={setReplenChanges}
        gridApi={gridApi}
      />
      <StyledReport fixed>
        <div className='report__actions'>
          <ControlledInput
            id='quick-filter'
            placeholder='Filter any column'
            value={quickFilter}
            handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setQuickFilter(event.target.value);
              gridApi?.setQuickFilter(event.target.value);
            }}
            label='Grid filter'
            type='text'
            classes='label--w-30'
          />
          <Tooltip
            title={
              <>
                <h3>
                  To generate a new
                  <br />
                  report, refresh the page
                </h3>
              </>
            }
            arrow
          >
            <div>
              <CustomButton
                title={exportStatus}
                classes='btn--w-200-px btn--black'
                endIcon={
                  <img src={DownloadIcon} alt='Download configuration' />
                }
                handleClick={getBulkOrderFile}
              />
            </div>
          </Tooltip>
        </div>
        {selectedDate && (
          <div className='last-updated'>
            * Last Updated {timeDiff(selectedDate)} day
            {timeDiff(selectedDate) === 1 ? '' : 's'} ago -{' '}
            <span className={lastUpdatedCellClass(selectedDate)}>
              {moment(selectedDate).format('YYYY-MM-DD')}
            </span>
          </div>
        )}
        <div className='report__grid ag-theme-balham'>
          <AgGridReact
            rowHeight={60}
            pagination
            defaultColDef={{
              resizable: true,
              flex: 1,
              wrapText: true
            }}
            tooltipShowDelay={0}
            enableCellTextSelection
            onGridReady={onGridReady}
            suppressDragLeaveHidesColumns
            headerHeight={70}
            suppressRowClickSelection
            rowGroupPanelShow='always'
            getRowId={(params) => params.data.id}
            rowData={rowData}
            excelStyles={[
              {
                id: 'stringType',
                dataType: 'String'
              }
            ]}
            components={{ renderStars: renderStars }}
            columnDefs={columnDefs}
          />
        </div>
      </StyledReport>
    </>
  );
};

export default Forecasting;
