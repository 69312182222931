import React, { SyntheticEvent } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';

import environment from '../../../../../environment';
import DisableIcon from '../../../../../assets/icons/disable.svg';
import EnableIcon from '../../../../../assets/icons/enable.svg';
import GenericModal from '../../../../../components/GenericModal';

// eslint-disable-next-line
export default ({ data, node }: any): JSX.Element => {
  const handleSubmit =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);

      if (moment.utc().isAfter(data.endDate)) {
        toast.error(
          'The scheduled report is permanently disabled as the end date is in the past'
        );
        setLoading(false);
        setOpen(false);
      } else {
        axios
          .post(
            `${environment.apiPath}enableScheduledReport`,
            {
              scheduledReportId: data.scheduledReportId,
              enabled: !data.enabled
            },
            { ...environment.params }
          )
          .then(
            (res) => {
              const status = res.data.enabled ? 'enabled' : 'disabled';
              node.setData({ ...data, enabled: res.data.enabled });
              toast.success(
                `Scheduled report ${data.reportName} successfully ${status}`
              );
            },
            (err) => toast.error(err.response.data.error)
          )
          .finally(() => {
            setLoading(false);
            setOpen(false);
          });
      }
    };

  return (
    <GenericModal
      iconBtn={data.enabled ? DisableIcon : EnableIcon}
      confirmationMessage={`Please confirm you want to ${
        data.enabled ? 'disable' : 'enable'
      } the ${data.frequency} generation of report ${data.reportName}`}
      handleSubmit={handleSubmit}
    />
  );
};
