import React, { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import axios from 'axios';

import environment from '../../environment';
import StyledFooter from './styles';

const Footer: React.FC = () => {
  const [version, setVersion] = useState('');

  const getVersion = () => {
    axios
      .get(`${environment.apiPath}getAppVersion`, {
        ...environment.params
      })
      .then((res) => setVersion(res.data.applicationVersion));
  };

  useEffect(() => {
    getVersion();
  }, []);

  return (
    <StyledFooter>
      <Container fixed>
        <p>
          <span>&#169; Vodafone 2020</span>
          <span style={{ float: 'right' }}>
            Portal {process.env.REACT_APP_CAVENDISH_PORTAL_VERSION}
            <span style={{ marginLeft: '50px' }}>Backend {version}</span>
          </span>
        </p>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
