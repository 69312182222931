import React, { ChangeEvent, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { GridApi, GridReadyEvent } from 'ag-grid-community';

import HeroProducts from '../../assets/heros/hero-products.png';
import HeroBanner from '../../components/HeroBanner';
import environment from '../../environment';
import StyledConfiguration from './styles';
import ControlledInput from '../../components/ControlledInput';
import CustomButton from '../../components/CustomButton';
import DownloadIcon from '../../assets/icons/download.svg';

const OriginSegmentMapping: React.FC = () => {
  const [rowData, setRowData] = useState([]);

  const [gridApi, setGridApi] = useState<GridApi>();
  const [quickFilter, setQuickFilter] = useState<string>('');

  const getRowData = (gridApiParam: GridApi) => {
    axios
      .get(`${environment.apiPath}getConfigItems?page=origin`, {
        ...environment.params
      })
      .then(
        (res) => {
          setRowData(res.data);
          if (res.data.length === 0) {
            gridApiParam?.showNoRowsOverlay();
          }
        },
        (err) => {
          setRowData([]);
          toast.error(err.message);
          gridApiParam?.showNoRowsOverlay();
        }
      );
  };

  const handleDataExport = () => {
    gridApi?.exportDataAsExcel({
      fileName: 'OriginSegmentMapping.xlsx'
    });
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api?.showLoadingOverlay();
    setGridApi(params.api);
    getRowData(params.api);
  };

  const columnDefs = [
    { field: 'origin', maxWidth: 80, enableRowGroup: true },
    { field: 'originName', enableRowGroup: true },
    { field: 'segment', maxWidth: 90, enableRowGroup: true },
    { field: 'segmentName', enableRowGroup: true },
    { field: 'subinventory', enableRowGroup: true },
    { field: 'warehouse', maxWidth: 100, enableRowGroup: true },
    { field: 'customerClass', enableRowGroup: true },
    {
      field: 'waitForNOMS',
      valueGetter: (params: any) =>
        params.data ? (params.data.waitForNOMS ? 'Yes' : 'No') : '',
      enableRowGroup: true
    },
    {
      field: 'edwReporting',
      headerName: 'EDW Reporting',
      valueGetter: (params: any) =>
        params.data ? (params.data.edwReporting ? 'Yes' : 'No') : '',
      enableRowGroup: true
    },
    {
      field: 'allowBackOrders',
      headerName: 'Supports Backorders',
      valueGetter: (params: any) =>
        params.data ? (params.data.allowBackOrders ? 'Yes' : 'No') : '',
      enableRowGroup: true
    },
    {
      field: 'courierUpdates',
      headerName: 'Send Courier Updates',
      valueGetter: (params: any) =>
        params.data ? (params.data.courierUpdates ? 'Yes' : 'No') : '',
      enableRowGroup: true
    }
  ];

  return (
    <>
      <HeroBanner title='Origin Segment Mapping' background={HeroProducts} />
      <StyledConfiguration fixed>
        <div className='configuration__actions'>
          <ControlledInput
            id='quick-filter'
            placeholder='Filter any column'
            value={quickFilter}
            handleChange={(event: ChangeEvent<HTMLInputElement>) => {
              setQuickFilter(event.target.value);
              gridApi?.setQuickFilter(event.target.value);
            }}
            label='Grid filter'
            type='text'
            classes='label--w-30'
          />
          <div>
            <CustomButton
              type='button'
              classes='btn--w-200-px btn--black'
              title='Export'
              endIcon={<img src={DownloadIcon} alt='Download configuration' />}
              handleClick={handleDataExport}
            />
          </div>
        </div>
        <div className='configuration__grid ag-theme-balham'>
          <AgGridReact
            rowHeight={30}
            defaultColDef={{
              resizable: true,
              flex: 1,
              minWidth: 100,
              filter: 'agTextColumnFilter'
            }}
            enableCellTextSelection
            suppressRowClickSelection
            pagination
            rowData={rowData}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            rowGroupPanelShow='always'
            suppressDragLeaveHidesColumns
          />
        </div>
      </StyledConfiguration>
    </>
  );
};

export default OriginSegmentMapping;
